<template>
  <nav class="header-nav" v-if="categories.length > 0">

    <ul class="menu menu--category">
        <li class="menu-item "><span class="menu-link is-current" id="cat-0" @click.self="hideElements( $event )">Alle</span></li>
      <li class="menu-item" v-for="(cat, idx) in categories" v-bind:key="idx">
                    <span :id="'cat-' + cat.uid" class="menu-link"
                          @click.self="hideElements( $event )">{{cat.title}}</span>
      </li>
    </ul>
    <span class="pointer"></span>

  </nav>
</template>

<script>

	import EventsBus from '../../utils/EventsBus';

    export default {
        name: "KappCategoryNav",
        props: [ 'categories' ],
        data() {
            return {
                selectedCategory: 0,
                position: 0
            };
        },
        methods: {
            hideElements: function ( event ) {
                let catId = '',
                    target = '';
                if ( typeof event === 'object' ) {
                    catId = event.target.id;
                    target = event.target;
                }
                else if ( typeof event === 'string' ) {
                    if ( event === 'none' || event === '' ) {
                        catId = '';

                        target = this.$el.querySelector('#none');
                    }
                    else {
                        catId = event;

                        target = this.$el.querySelector('#' + catId);
                    }
                }

                this.$el.querySelectorAll('.menu-link').forEach(( el ) => el.classList.remove('is-current'));

                if ( target ) {
                    target.classList.toggle('is-current');
                    this.movePointerTo(target);
                }


                if ( catId === 'none' ) catId = '';
                this.selectedCategory = catId;
                EventsBus.trigger('switchContentCategory', catId);

                // return false;
            },

            movePointerTo: function ( el = false ) {

                let left = 0, width = 66;// magic number :]
                if(this.categories.length === 0 ) return false;

                if ( el ) {
                    left = el.offsetLeft;
                    width = el.offsetWidth;
                }
                else {
                    el = this.$el.querySelector('li:first-child');
                    if( el ) {
                        el.classList.add('is-active');
                        el.querySelector('.menu-link').classList.add('is-current');
                        left = el.offsetLeft;
                        width = el.offsetWidth;
                    }
                }
                if( el !== null ) {
                    this.$el.style.setProperty('--pointerLeftOffset', left + 'px');
                    this.$el.style.setProperty('--pointerWidth', width + 'px');
                }
            }
        },
        computed: {},
        mounted() {
            this.$nextTick(this.movePointerTo);
            EventsBus.on('category:goto', this.hideElements);
        },
        watch: {
            categories: function () {

                    this.$nextTick(function () {

                        let elm = this.$el.querySelector('.menu-link.is-current');
                        if ( elm ) {
                            this.movePointerTo(elm);
                        }
                    });

            },
            '$route': function () {
                this.position = 0;
                this.movePointerTo(false);
            }
        }
    };
</script>

<style scoped>

</style>
